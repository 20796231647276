import React, { useState } from 'react';

import { useViewportScroll } from 'framer-motion';
import { address } from '../../data.yaml';
import {
  Layout,
  Navigation,
  Header,
  Observable,
  Intro,
  Reasons,
  Team,
  Showcase,
  Contact,
  Banner,
  Skills,
  Leistungen,
  Inside,
  Kunden,
  Referenzen,
  Jobs,
} from '../components';
import { graphql } from 'gatsby';

const IndexPage = props => {
  const [progress, setProgress] = useState(0);
  const { scrollYProgress } = useViewportScroll();

  scrollYProgress.onChange(yProgress => setProgress(yProgress));

  return (
    <Layout address={address} title="re:spkt Kommunikationsagentur | starke Ideen aus Stuttgart">
      <Navigation
        progress={progress}
        items={[
          {
            id: 'agentur',
            text: 'Agentur',
            to: '#agentur',
          },
          {
            id: 'leistungen',
            text: 'Leistungen',
            to: '#leistungen',
          },
          {
            id: 'referenzen',
            text: 'Referenzen',
            to: '#referenzen',
          },
          {
            id: 'jobs',
            text: 'Jobs',
            to: '#jobs',
          },
        ]}
      >
        {({ setActivePageFragment }) => (
          <>
            <Observable callback={() => setActivePageFragment('start')}>
              <Header />
            </Observable>
            <Observable callback={() => setActivePageFragment('agentur')}>
              <Intro image={props.data.headerImage.childImageSharp.fluid} />
              <Reasons
                image={props.data.star.childImageSharp.fluid}
                imageMobile={props.data.starMobile.childImageSharp.fluid}
              />
              <Team
                controller={props.data.controller.childImageSharp.fluid}
                data={props.data.allPrismicMitarbeiter.edges}
              />
              <Showcase data={props.data.prismicShowcase.data} />
              <Contact
                data={[
                  {
                    id: 1,
                    text:
                      'Persönliche Beratung, kompetente Antworten oder auch nur mal eine spontane Einschätzung.',
                    ctaText: 'Rufen Sie uns einfach an!',
                    link: 'tel:+4907119688080',
                    linkText: '0711 968 808 0',
                  },
                  {
                    id: 2,
                    text:
                      'Unverbindliche Anfrage, konkreter Wunsch oder einfach etwas, das Sie uns mitteilen möchten.',
                    ctaText: 'Und ab geht die Post!',
                    link: 'mailto:info@respkt.de',
                    linkText: 'info@respkt.de',
                  },
                  {
                    id: 3,
                    text:
                      'Entspanntes Meeting, persönliches Briefing oder einfach ein Tässchen Kaffee.',
                    ctaText: 'Herzlich willkommen!',
                    link:
                      'https://www.google.de/maps/place/re:spkt+GmbH/@48.723665,9.1434158,16.17z/data=!4m5!3m4!1s0x4799dded0ce644f5:0xa32e40a8efe3d4ca!8m2!3d48.7238514!4d9.1462912',
                    linkText: 'Ihr Weg zu re:spkt',
                  },
                ]}
              />
            </Observable>
            <Observable callback={() => setActivePageFragment('leistungen')}>
              <Banner />
              <Skills image={props.data.sneaker.childImageSharp.fluid} />
              <Leistungen />
              <Inside groundImage={props.data.groundImage.childImageSharp.fluid} />
            </Observable>
            <Observable callback={() => setActivePageFragment('referenzen')}>
              <Kunden data={props.data.prismicKunden.data.kunden} />
              <Referenzen data={props.data.allPrismicReferenz.edges} />
            </Observable>
            <Observable callback={() => setActivePageFragment('jobs')}>
              <Jobs
                image={props.data.shirt.childImageSharp.fluid}
                jobs={props.data.prismicJob.data}
              />
            </Observable>
          </>
        )}
      </Navigation>
    </Layout>
  );
};

export default IndexPage;

export const props = graphql`
  query MyQuery {
    prismicKunden {
      data {
        kunden {
          kunden_bild {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 219) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          kunden_name {
            text
          }
        }
      }
    }
    prismicJob {
      data {
        basis_job_text {
          html
        }
        basis_job_title {
          text
        }
        job_toggle
        jobs {
          job_description {
            html
          }
          job_email {
            text
          }
          job_title {
            text
          }
        }
      }
    }
    allPrismicReferenz {
      edges {
        node {
          data {
            body {
              ... on PrismicReferenzBodyMultipleImageStage {
                id
                slice_type
                primary {
                  image_left_large {
                    localFile {
                      childImageSharp {
                        fluid(quality: 80, maxWidth: 982) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  image_upper_small {
                    localFile {
                      childImageSharp {
                        fluid(quality: 80, maxWidth: 322) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  image_lower_small {
                    localFile {
                      childImageSharp {
                        fluid(quality: 80, maxWidth: 322) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicReferenzBodySoloImageStage {
                id
                slice_type
                primary {
                  solo_image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 100, maxWidth: 1320) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
            referenz_text {
              html
            }
            referenz_title {
              text
            }
          }
        }
      }
    }
    allPrismicMitarbeiter {
      edges {
        node {
          data {
            employee_job {
              text
            }
            employee_name {
              text
            }
            employee_image {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 327) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicShowcase {
      data {
        body {
          ... on PrismicShowcaseBodySingleShowcase {
            id
            slice_type
            primary {
              single_showcase_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1320) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicShowcaseBodyMultiShowcase {
            id
            slice_type
            primary {
              multi_showcase_left {
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 982) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              multi_showcase_lower {
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 322) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              multi_showcase_upper {
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 322) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "stuttgart.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    star: file(relativePath: { eq: "star-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    starMobile: file(relativePath: { eq: "star-mobile1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    controller: file(relativePath: { eq: "controller.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sneaker: file(relativePath: { eq: "sneaker.png" }) {
      childImageSharp {
        fluid(maxWidth: 858, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    groundImage: file(relativePath: { eq: "grd.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 642, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shirt: file(relativePath: { eq: "shirt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
